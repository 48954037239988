.sidenav {
  flex: 1;
  min-height: 110vh;
  background-color: #1ca35e;
  width: 50px !important;
  color: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  transition: all 0.5s;
  z-index: 0 !important;

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }
  .ul {
    color: black !important;
  }
 
  .center {
    padding-left: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-top: 5rem;
      width: 180px;

      .title {
        font-size: 10px;
        font-weight: bold;
        color: white;
        margin-top: 15px;
        margin-bottom: 5px;
      }
   

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        margin-top: 1rem;
        cursor: pointer;
        transition: all 0.5s;
        // width: 150px;
        width: 160px;
        border-radius: 10px;
        border: white;
      
       

        .icon {
          font-size: 22px !important;
          font-size: 20px !important;
          color: white;
          margin-right: 1.2rem;
        }
        $primary-theme-color: #003876;
        $theme-lightgreen-color: #61b137;
        $secondary-theme-color: #b4d0c1;
        span { 
          font-size: 1rem;
          font-size:1rem;
          font-weight: 600;
          color: white;
          justify-content: start;
          text-align: left !important;
        }
      }
    }
  }
  .span-ic {
    display: flex;
    flex-direction: row;
  }
  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #003876;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }

      &:nth-child(2) {
        background-color: #333;
      }
      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}
.sidenav:hover {
  width: 200px !important;
  width: 210px !important;
  transition: all 0.7s;
}
