.d-flex-row {
  display: flex;
  flex-direction: row;
}

.justify-content-between {
  justify-content: space-between;
}

.container {
  margin: 10px 20px;
  border: 1px solid #efefef;
  padding: 10px;
}

.sale-price-input {
  width: 70px;
}
