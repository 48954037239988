.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(180deg, rgb(2, 0, 36) 0%, rgb(246 148 33) 100%);
  font-size: 20px;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4;
    height: 400px;
    width: 320px;
    justify-content: center;
    border-radius: 5px;

    img {
      width: 62%;
      margin-bottom: 30px;
    }
    .login-title {
      font-weight: 800;
      color: #003876;
      font-size: 20px;
    }
    input {
      width: 200px;
      height: 30px;
      margin: 10px;
      background-color: white;
      border: none;
      border-radius: 5px;
    }

    button {
      width: 210px;
      height: 30px;
      margin: 10px;
      background-color: #61b137;
      color: white;
      font-weight: bold;
      border: none;
      cursor: pointer;
      border-radius: 5px;
    }
    span {
      font-size: 12px;
      color: red;
      margin-top: 10px;
    }
  }
}
