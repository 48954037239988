.d-flex {
  display: flex;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.field-error {
  background-color: #edaeae !important;
  outline-color: red;
}
.newuser {
  width: 100%;
  display: flex;
  .newContainer {
    flex: 6;
    h3 {
      color: #003870;
    }
    .top,
    .contact-container,
    .billing-container,
    .shipping-continer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;
      h1 {
        color: lightgray;
        font-size: 20px;
      }
      .contact,
      .billing,
      .shipping {
        flex: 1;
        form {
          .form-row {
            display: flex;

            .form-group {
              margin: 10px 20px;

              .form-input {
                width: 100%;
                border: none;
                border-radius: 5px;
                background-color: #efefef;
                padding: 7px;
                margin-top: 7px;
                margin-bottom: 20px;

                label {
                  color: #003870;
                  font-weight: bold;
                  font-size: 15px;
                  margin-left: 8px;
                }
              }
            }
          }
        }
      }
    }
    button[type='submit'] {
      width: 150px;
      border: none;
      padding: 10px;
      background-color: #003870;
      color: white;
      font-weight: bold;
      cursor: pointer;
      margin-top: 10px;
      border-radius: 5px;
      margin-bottom: 10px;
      margin-left: 50px;
    }
  }
}

.ReactTags__tag {
  background-color: #efefef;
  border: 1px solid grey;
  margin: 5px 5px;
}

.ReactTags__remove {
  border: none;
  background-color: #efefef;
}
