center {
  width: 100mm;
}

img {
  width: 100px;
}

table {
  width: 100%;
  text-align: center;
}
.border {
  border: 1px solid;
  border-left: none;
  border-right: none;
}

.border_dash {
  border: 1px solid;
  border-left: none;
  border-right: none;
}

@media print {
  @page {
    margin: 0mm;
    size: 80mm;
    page-break-after: avoid;
    page-break-before: avoid;
    page-break-inside: avoid;
  }

  * {
    margin: 0;
    padding: 0;
    font-size: 10px;
  }

  center {
    height: 100%;
    width: 100%;
    page-break-after: avoid;
    page-break-before: avoid;
    page-break-inside: avoid;
  }

  .terms-and-condition {
    font-size: 14px;
  }

  img {
    width: 100px;
  }

  div,
  table {
    width: 100%;
    text-align: center;
    page-break-after: avoid;
    page-break-before: avoid;
    page-break-inside: avoid;
  }
}
