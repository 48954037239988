.list {
  display: flex;
  width: 100%;
  .icons {
    color: #1ca35e !important;
  }
  .list-container {
    flex: 6;
  }
}
